<template>
  <div>
    <div>
      <div v-if="optionedSelectedDataTable.length > 0" class="p-4">
        <button
          @click="toggleConfirmationModal(true)"
          class="-ml-2 px-6 flex bg-success-flattlo hover:bg-green-600 py-3 rounded-lg shadow font-normal text-xl text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
            />
          </svg>
          Liberar unidad
        </button>
      </div>
      <TableList
        :columnsHeaders="tableHeadersColumns"
        :tableData="tableData"
        buttonNameAction="event-quote"
        routePush="Cotización"
      />
    </div>
    <div>
      <ConfirmationModal
        :propertySelected="optionedSelectedDataTable"
        baseColor="green"
        msg="¿Esta seguro de que desea liberar esta unidad?, Esto permitira que cualquier asesor pueda cotizar y ofrecer la unidad."
        title="Confirmación de unidad"
        statusForUpdate="OPTIONED"
      />
    </div>
  </div>
</template>

<script>
import TableList from "../components/TableList.vue";
import ConfirmationModal from "../components/ConfirmationModal.vue";

import { mapState } from "vuex";

import quotesApi from "../../quotes/services/quotes.api";
import currencies from "../../../helpers/currencies";
import { api as projectApi } from "../../projects/services";

export default {
  data() {
    return {
      loading: false,
      dataForPopulateSelector: [],
      tableData: [],
      project: null,
      tableHeadersColumns: [
        {
          headerName: "Número",
          valueGetter: ({ data }) => {
            return this.getMainProperty(data.quote_details).unit_number;
          },
          width: 170,
          checkboxSelection: true,
        },
        {
          headerName: "Tipo",
          valueGetter: ({ data }) => {
            const mainProperty = this.getMainProperty(data.quote_details);
            return mainProperty.project_unit_type
              ? mainProperty.project_unit_type.type
              : "--";
          },
          width: 130,
        },
        {
          headerName: "Hab.",
          valueGetter: ({ data }) => {
            const mainProperty = this.getMainProperty(data.quote_details);
            return mainProperty.rooms || "--";
          },
          width: 130,
          filter: "agNumberColumnFilter",
          filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        },
        {
          headerName: "Baños",
          valueGetter: ({ data }) => {
            const mainProperty = this.getMainProperty(data.quote_details);
            return mainProperty.bathrooms || "--";
          },
          width: 130,
          filter: "agNumberColumnFilter",
          filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        },
        {
          headerName: "Área hab.",
          valueGetter: ({ data }) => {
            const mainProperty = this.getMainProperty(data.quote_details);
            return mainProperty.living_area || "";
          },
          width: 160,
          //filter: 'agNumberColumnFilter',
          filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        },
        {
          headerName: "Asesor",
          valueGetter: (params) =>
            params.data.user.first_name + " " + params.data.user.last_name,
          width: 260,
        },
        {
          headerName: "Cliente",
          valueGetter: (params) => params.data.client.first_name,
          width: 260,
        },
        {
          headerName: "Estado",
          width: 260,
          valueGetter: () => {
            return this.$tc("message.Optioned", 1, this.user.countryCode);
          },
          valueFormatter: () => {
            return this.$tc("message.Optioned", 1, this.user.countryCode);
          },
        },
        {
          headerName: "Ver",
          field: "id",
          cellRenderer: "agGroupButton",
          filter: false,
          width: 130,
          cellRendererParams: () => {
            return {
              buttons: [
                {
                  color: "primary",
                  icon: "icon-eye",
                  event: "event-quote",
                },
              ],
            };
          },
        },
      ],
    };
  },
  methods: {
    toggleConfirmationModal(v) {
      this.$store.commit("optioned/modalConfirmationOptioned", v);
    },
    getUrl(id) {
      return `${process.env.VUE_APP_PDF_URL}/${id}/pdf`;
    },
    async getOptionedProperties(projectId) {
      this.tableData = await quotesApi.getByStatus(projectId, "OPTIONED");
    },
    getFormatCurrency(value) {
      return currencies.currencyFormatter({ value });
    },
    getMainProperty(quoteDetail) {
      const mainPropertyFilter = quoteDetail.filter((property) => {
        return property.property.property_type_id !== "WAREHOUSE" &&
          property.property.property_type_id !== "PARKING"
          ? property.property
          : null;
      });

      return mainPropertyFilter.length ? mainPropertyFilter[0].property : null;
    },
  },
  computed: {
    ...mapState("optioned", ["optionedSelected", "optionedSelectedDataTable"]),
    ...mapState("auth", ["user"]),
  },
  components: {
    TableList,
    ConfirmationModal,
  },
  async mounted() {
    const projectId = this.$route.params.id;

    await this.getOptionedProperties(projectId);
    this.project = await projectApi.get(projectId);

    this.$root.$on("reload-optioned", () => {
      this.getOptionedProperties(projectId);
    });

    this.$store.state.project.breadcrumbs = [
      {
        pageName: this.$tc('message.optioned', 2, this.user.countryCode),
        route: '/opcionados',
      },
      {
        pageName: `Listado de ${this.$tc('message.optioned',2, this.user.countryCode )} ${ this.project ? this.project.name : ''}`,
        route: `/opcionados/${this.$route.params.id}`,
      },
    ];
  },
};
</script>
