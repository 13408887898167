<template>
  <div>
    <div>
      <div class="mt-6">
        <ag-grid-vue
          class="ag-theme-material ag-grid-table h-screen"
          :defaultColDef="defaultColDef"
          :columnDefs="columnsHeaders"
          :rowData="tableData"
          :pagination="true"
          :frameworkComponents="frameworkComponents"
          :paginationPageSize="itemsPerPage"
          :floatingFilter="true"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
          enableCellChangeFlash="true"
          @grid-ready="onGridReady"
        ></ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import * as lang from "vuejs-datepicker/src/locale";
import AgGroupButton from "../../../components/AgGridComponents/AgGroupButton.vue";
export default {
  props: {
    columnsHeaders: Array,
    tableData: Array,
    buttonNameAction: String,
    routePush:String
  },
  data() {
    return {
      itemsPerPage: 7,
      language: "es",
      languages: lang,
      rowSelection: [],
      fromDate: null,
      toDate: null,
      defaultColDef: {
        filter: true,
        sortable: true,
        width: 120,
        maxWidth: 100,
        filterParams: {
          alwaysShowBothConditions: true,
          defaultJoinOperator: "OR"
        }
      },
      gridApi: null,
      columnApi: null,
      frameworkComponents: null
    };
  },
  methods: {
    onAgGroupEventButton() {
      this.$root.$on(`${this.buttonNameAction}`, data => {
        this.$router.push({
          name: this.routePush,
          params: {
            id: data.id
          }
        });
      });
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.sendSelectionsToVuex(selectedRows, "OptionedTable", "optioned");
    },
    sendSelectionsToVuex(data, tableFrom, vuexModule) {
      this.$store.commit(`${vuexModule}/dataFrom${tableFrom}`, data);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  },
  components: {
    AgGridVue
  },
  beforeMount() {
    this.frameworkComponents = {
      agGroupButton: AgGroupButton
    };
    this.onAgGroupEventButton();
  },
  destroyed() {
    this.sendSelectionsToVuex([], "OptionedTable", "optioned");
  }
};
</script>

<style lang="scss" scoped></style>
